<template>
    <div class="dark-mode">
        <header>
            <nav class="navbar navbar-expand-lg bg-dark navbar-dark">
                <div class="container">
                    <a class="navbar-brand text-gold" href="#home">
                        <img :src="require('@/assets/logo_with_name.png')" v-on:click="show('home')" alt="Logo" class="comp-logo">
                    </a>
                    <button class="navbar-toggler" type="button" v-on:click="toggleMenu">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item" v-bind:class="{ 'navbar-selected' : windowName == 'home' }"><a
                                    class="nav-link" href="#home" v-on:click="show('home')">Startseite</a></li>
                            <li class="nav-item" v-bind:class="{ 'navbar-selected' : windowName == 'portfolio' }"><a
                                    class="nav-link" href="#portfolio" v-on:click="show('portfolio')">Portfolio</a></li>
                            <div class="dropdown">
                                <a class="nav-link dropbtn">Tools </a>
                                <div class="dropdown-content">
                                    <a href="https://ipcheck.vegabyte.de" target="_blank">Domain IP Check</a>
                                </div>
                            </div>
                            <li class="nav-item" v-bind:class="{ 'navbar-selected' : windowName == 'contact' }"><a
                                    class="nav-link" href="#contact" v-on:click="show('contact')">Kontakt</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

        <main>
            <div class="construction-message" v-if="showMaintenance">
                <button class="close-btn" v-on:click="showMaintenance = !showMaintenance">×</button>
                <h1>Die Seite im Wartungsmodus</h1>
                <p>Wir arbeiten derzeit an unserer Website. Bitte schauen Sie später wieder vorbei.</p>
            </div>
            <component :is="windowName"></component>
        </main>

        <footer class="bg-footer py-3">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <p class="text-light">&copy; 2024 VegaByte. Alle Rechte vorbehalten.</p>
                    </div>
                    <div class="col-md-6 text-md-right">
                        <!-- Facebook deaktivert, da aktuell kein Facebook Account genutzt wird -->
                        <!--<a href="#" class="text-gold mr-3"><i class="fa-brands fa-square-facebook fa-2x"></i></a>-->
                        <a href="https://wa.me/4915147088996" target="_blank"
                            class="text-gold mr-3 footer-icon-spacing"><i class="fa-brands fa-whatsapp fa-2x"></i></a>
                        <a href="https://www.instagram.com/vega.byte/" target="_blank"
                            class="text-gold footer-icon-spacing"><i class="fab fa-instagram fa-2x"></i></a>
                    </div>
                    <div class="col-md-3">
                        <a href="#impressum" v-on:click="show('impressum')">Impressum</a>
                        <a href="#privacypolicy" v-on:click="show('privacypolicy')"><br>Datenschutzerklärung</br></a>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>
    import Components from "./components"

    //Wichtige Seiten
    import impressum from "./important/impressum.vue"
    import privacypolicy from "./important/privacypolicy.vue"

    //Generelle Seiten
    import home from "./pages/home.vue"
    import contact from "./pages/contact.vue"
    import portfolio from "./pages/portfolio.vue"

    //Mitarbeiter Vorstellungen
    import markusB from "./workers/markusB.vue"
    import florianG from "./workers/florianG.vue"

    export default Components.register({
        name: "Framework",
        data: function () {
            return {
                windowName: "home",
                showMaintenance: false,
                componentList:  ['impressum', 'privacypolicy', 'home', 'contact', 'portfolio', 'markusB', 'florianG' ],
            }
        },
        methods: {
            show: function (window) {
                this.windowName = window
            },
            close: function () {
                this.windowName = ""
            },
            toggleMenu: function () {
                var displayStyle = document.getElementsByClassName("collapse")[0].style.display;

                if (displayStyle == "block") document.getElementsByClassName("collapse")[0].style.display = "none";
                else document.getElementsByClassName("collapse")[0].style.display = "block";

            },
            checkURL: function () {
                if(window.location.href.includes('#')){
                    var currentPage = window.location.href.split("#")[1];
                    for (var i = 0; i < this.componentList.length; i++) {
                        if (currentPage.toLowerCase() == this.componentList[i].toLowerCase()) {
                            this.show(this.componentList[i])
                            i = this.componentList.length;
                        }
                    }
                } else {
                    this.show("home");
                }
            },
            checkHashChangeURL: function (change) {
                if(change.includes('#')){
                    var currentPage = change.split("#")[1];
                    for (var i = 0; i < this.componentList.length; i++) {
                        if (currentPage.toLowerCase() == this.componentList[i].toLowerCase()) {
                            this.show(this.componentList[i])
                            i = this.componentList.length;
                        }
                    }
                } else {
                    this.show("home");
                }
            }
        },
        components: {
            //WICHTIG
            impressum,
            privacypolicy,

            //Seiten
            home,
            contact,
            portfolio,

            //Mitarbeiter
            markusB,
            florianG,
        },
        mounted() {
            window.addEventListener('hashchange', function (e) {
                components.Framework.checkHashChangeURL(e.newURL);
            });
        },
        created() {
            this.checkURL();
        },
    })
</script>