<template>
    <div>
        <section class="hero py-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h1 class="display-4">Unser Portfolio</h1>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4"><img src="img/logo.d0ca7e5e.png"
                            alt="Logo" class="img-fluid rounded-circle"></div>
                </div>
            </div>
        </section>
        
        <section class="py-5 bg-dark">
            <div class="container mt-5">
                <div class="row align-items-center">
                    <div class="col-md-10">
                        <h1 class="lead text-light">Hier finden Sie eine kleine Übersicht der Projekte die bereits umgesetzt wurden.
                        Aktuell haben wir 2 vollendete Projekte durchgeführt. Weitere Projekte werden natürlich folgen und
                        nach Absprache hier hinterlegt.</h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5 workers">
            <div class="container mt-5">
                <div class="row g-4">
                    <!-- Portfolio Box -->
                    <div class="col-md-4">
                        <div class="portfolio-box">
                            <img :src="require('@/assets/portfolio/vTogether.png')" class="img-fluid" alt="Bild 2">
                            <div class="overlay">
                                <div class="text">
                                    <h5>vTogether</h5>
                                    <p>vTogether war das 2. Projekt von Markus B. Dort wurde über 2 Jahre hinweg ein Server auf der alt:V Basis entwickelt. Zum Ende des Projekts Mitte 2024, hatte das Projekt ca. 13.000 Mitglieder, davon 230 Spieler gleichzeit auf dem Server. Über die Jahre konnte vielen Spielern eine Freude bereitet werden. </p>
                                    <a href="https://vtogether.de/" target="_blank" class="btn btn-gold btn-lg">Mehr erfahren</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Portfolio Box 1 -->
                    <div class="col-md-4">
                        <div class="portfolio-box">
                            <img :src="require('@/assets/portfolio/IslandV.png')" class="img-fluid" alt="Bild 1">
                            <div class="overlay">
                                <div class="text">
                                    <h5>IslandV</h5>
                                    <p>IslandV ist ein GTA5 RageMP Projekt, das von 2019 - 2022 betrieben wurde. Es wurden mehr als 270 Spieler auf dem Server gehalten. Es war eines der größten Projekte zur GTA RP Zeit.</p>
                                    <a href="https://IslandV.net" target="_blank" class="btn btn-gold btn-lg">Mehr erfahren</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Portfolio Box 2 -->
                    <div class="col-md-4">
                        <div class="portfolio-box">
                            <img :src="require('@/assets/portfolio/vFight.png')" class="img-fluid" alt="Bild 2">
                            <div class="overlay">
                                <div class="text">
                                    <h5>vFight</h5>
                                    <p>Derzeit befindet sich vFight in der Entwicklung. Es soll eine Seite für eine Gaming Comunity entwickelt werden.</p>
                                    <a href="https://vFight.de" target="_blank" class="btn btn-gold btn-lg">Mehr erfahren</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Weitere Portfolio-Boxen können hier hinzugefügt werden -->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "portfolio",
        props: {
            data: String
        },
        data() {
            return {}
        },
        methods: {
            show: function (window) {
                components.Framework.show(window)
            },
        },
        created: function () {

        },
        mounted: function () {

        },
        destroyed: function () {}
    })
</script>

<style>

</style>