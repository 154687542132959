<template>
    <div>
        Beispiel nehmen an: https://adminlte.io/themes/v3/pages/examples/profile.html
    </div>
</template>

<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "florianG",
        props: {
            data: String
        },
        data() {
            return {
            }
        },
        methods: { 
            show: function (window) {
                components.Framework.show(window)
            },
        },
        mounted: function () {
            this.show("markusB")
        },
        destroyed: function () {
        }
    })
</script>

<style scoped>
    @import "../../style/timeline.css";
</style>