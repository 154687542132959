<template>
    <div>
        <section class="hero py-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-8">
                        <h1 class="display-4">Kontaktieren Sie uns</h1>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>
        </section>

        <section class="py-5 bg-dark">
            <div class="container mt-5">
                <div class="row align-items-center">
                    <div class="col-md-10">
                        <h1 class="lead text-light">Sie haben noch offene Fragen, wollen sich ein Angebot einholen oder
                            wir sollen uns bei Ihnen melden?
                            Gar kein Problem, nutzen sie dazu bitte das unten stehende Formular. Wir nehmen dann
                            schnellstmöglich mit Ihnen Kontakt auf,
                            um Ihnen bestmöglich zu helfen. Sie wollen keinen Rückruf? Dann schreiben sie uns gerne
                            dazu, das wir uns lieber per Mail bei
                            Ihnen melden sollen.
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5 workers">
            <div class="container mt-5">
                <div class="row g-4">
                    <div class="col-md-2"></div>
                    <div class="contact-container col-md-8">
                        <h2 class="mb-4">Kontaktformular</h2>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="firstName">Vorname*</label>
                                    <input type="text" class="form-control" id="firstName" required>
                                </div>
                                <div class="form-group">
                                    <label for="email">E-Mail*</label>
                                    <input type="email" class="form-control" id="email" required>
                                    <small id="emailHelp" class="form-text text-muted">Bitte geben Sie eine gültige
                                        E-Mail-Adresse ein.</small>
                                </div>
                                <div class="form-group">
                                    <label for="company">Firma</label>
                                    <input type="text" class="form-control" id="company">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="lastName">Nachname*</label>
                                    <input type="text" class="form-control" id="lastName" required>
                                </div>
                                <div class="form-group">
                                    <label for="phone">Telefon</label>
                                    <input type="tel" class="form-control" id="phone">
                                    <small id="telHelp" class="form-text text-muted">Bitte geben Sie eine gültige
                                        Rufnummer ein.</small>
                                </div>
                                <div class="form-group">
                                    <label for="contactMethod">Bevorzugte Kontaktmöglichkeit*</label>
                                    <select class="form-control" id="contactMethod" required>
                                        <option value="Telefon">Telefon</option>
                                        <option value="Email">E-Mail</option>
                                        <option value="Beides">Telefon + E-Mail</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="message">Nachricht*</label>
                            <textarea class="form-control" id="message" rows="6" v-on:keydown="getAmount" v-on:blur="getAmount" required></textarea>
                            <small id="messageHelp" class="form-text text-muted">Der Text muss mindestens noch {{ minText }} Zeichen enthalten</small>
                        </div>

                        <button type="submit" id="sendButton" style="margin-top: 10px;" v-on:click="checkRequest()" class="btn btn-gold btn-block">Senden</button>
                        <div><span id="success" style="margin-left: 10px; color: grey; font-size: 12px;"></span></div> 
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import jQuery from "../../scripts/jquery-3.2.1.min.js";
    let $ = jQuery;
    import Windows from "../windows.js"

    export default Windows.register({
        name: "contact",
        props: {
            data: String
        },
        data() {
            return {
                minText: 100,
            }
        },
        methods: {
            getAmount: function() {
                var amount = document.getElementById('message').value.length;
                this.minText = 100 - amount < 0 ? 0 : 100 - document.getElementById('message').value.length;
            },
            checkRequest: function() {
                if (document.getElementById('firstName').value.length == 0) {
                    alert('Bitte geben Sie Ihren Vornamen ein.');
                    return false;
                }

                if (document.getElementById('lastName').value.length == 0) {
                    alert('Bitte geben Sie Ihren Vornamen ein.');
                    return false;
                }

                var email = document.getElementById('email').value;
                var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (!emailPattern.test(email)) {
                    alert('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
                    return false;
                }
                
                if (document.getElementById('message').value.length == 0) {
                    alert('Bitte geben Sie Ihre Anfrage ein.');
                    return false;
                }

                this.sendRequest();
            },
            sendRequest: function(){
                var firstname = document.getElementById('firstName').value;
                var lastname = document.getElementById('lastName').value;
                var email = document.getElementById('email').value;
                var phone = document.getElementById('phone').value;
                var company = document.getElementById('company').value;
                var contactMethod = document.getElementById('contactMethod').value;
                var message = document.getElementById('message').value;
                
                $("#sendButton").prop("disabled", true);

                $.ajax({
                    type: 'GET',
                    url: 'https://test.vegabyte.de/request.php?firstname=' + firstname + '&lastname=' + lastname +
                    '&email=' + email + '&phone=' + phone + '&company=' + company + '&contactMethod=' + contactMethod + '&message=' + message,
                    data: { },
                    dataType : 'json',
                    success: function (response) {
                    },
                    error: function(XMLHttpRequest, textStatus, errorThrown) { 
                    }
                });

                document.getElementById("success").innerHTML = "Ihre Anfrage wurde eingereicht!";
            }
        },
        mounted: function () {},
        destroyed: function () {}
    })
</script>