<template>
    <div>
        <section class="content bg-dark" style="padding: 20px 0px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card card-primary card-outline">
                            <div class="card-body box-profile">
                                <div class="text-center">
                                    <img class="profile-user-img img-fluid img-circle profilepicture"
                                        :src="require('@/assets/workers/markusB.jpg')" alt="User profile picture">
                                </div>
                                <h3 class="profile-username text-center">Markus Brünner</h3>
                                <p class="text-muted text-center">Entwickler</p>
                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-calendar mr-1"></i> Alter</b></br> <span
                                            class="float-right">28 Jahre</span>
                                    </li>
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-computer mr-1"></i> In der Entwicklung tätig
                                            seit</b></br> <span class="float-right">2010, angefangen mit Java</span>
                                    </li>
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-timer mr-1"></i> Hobbys</b></br><span
                                            class="float-right">Entwicklung von Mods/Scripts für Spiele, Family-Life,
                                            Fitness</span>
                                    </li>
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-comment mr-1"></i> Sonstiges</b></br><span
                                            class="float-right">Führerschein der Klasse B.</span>
                                    </li>
                                </ul>
                            </div>

                        </div>


                        <div class="card card-primary" style="margin-top: 10px;">
                            <div class="card-header">
                                <h3 class="card-title">Über mich</h3>
                            </div>

                            <div class="card-body">
                                <strong><i class="fas fa-book mr-1"></i> Bildung</strong>
                                <p class="text-muted">
                                    - Zertifizierung zum Software Tester</br>
                                    - ITIL Zertifizierung
                                </p>
                                <hr>
                                <strong><i class="fas fa-map-marker-alt mr-1"></i> Wohnort</strong>
                                <p class="text-muted">Nordrhein-Westfalen, Deutschland</p>
                                <hr>
                                <strong><i class="fas fa-pencil-alt mr-1"></i> Skills</strong>
                                <p class="text-muted">
                                    Teamfähigkeit, Analytisches Denken, Kritikfähigkeit, Kommunikative Kompetenz
                                </p>
                                <hr>
                                <strong><i class="fas fa-pencil-alt mr-1"></i> Technische Skills</strong>
                                <p class="text-muted">
                                    Javascript, C#, Java, PHP, VueJS, jQuery, MySQL
                                </p>
                                <p class="text-muted">
                                    Umgang mit Windows und Unix, sowie Makro Entwicklung für Office Produkte
                                </p>
                            </div>

                        </div>

                    </div>

                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body">
                                <div class="timeline">
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">2024</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                1 Aug 2024
                                            </span>
                                            <h3 class="timeline-header no-border"> Es wurde angefragt, ob die Seite für das
                                                Projekt <a href="https://vFight.de">vFight</a> entwickelt werden.
                                                Die Entwicklung befindet sich derzeit in Bearbeitung.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                31 Jul 2024
                                            </span>
                                            <h3 class="timeline-header no-border"> Durch geringe Spielerzahlen wurde da
                                                Projekt <a href="https://vTogether.de">vTogether</a> beendet.
                                                Es wird sich jetzt auf neue Projekte und Aufträge konzentriert.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">2023</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                12 Apr 2023
                                            </span>
                                            <h3 class="timeline-header no-border"> Es wurden kleinere Plugins für <a
                                                    href="https://www.spigotmc.org/">SpigotMC</a> geschrieben. Unter
                                                anderem ein Redeem Code Plugin und ein Chest Shop Plugin.
                                                Dieses wurde dann nach Fertigstellung veröffentlicht, damit jeder dieses
                                                nutzen kann.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-camera-film text-bg-info"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                31 Jan 2023
                                            </span>
                                            <h3 class="timeline-header"> <a
                                                    href="https://www.youtube.com/watch?v=RQgcUF46DkQ"></a> [vTogether]
                                                Leak zum Update 0.4.3
                                            </h3>
                                            <div class="timeline-body">
                                                <div class="ratio ratio-16x9"> <iframe
                                                        src="https://www.youtube.com/embed/RQgcUF46DkQ?si=wzplVFWg-8afFbz4"
                                                        allowfullscreen></iframe> </div>
                                            </div>
                                            <div class="timeline-footer">
                                                In dem Update wurde fürs Casino alles neu gemacht. Es wurde der
                                                Einarmige Bandit hinzugefügt mit einem Zufallsprinzip. Des weiteren
                                                wurde ein Multiplayer Roulette Tisch geschrieben.
                                                Dort konnte man sehen, wer auf welches Feld einen Betrag gesetzt hat. Am
                                                Ende konnte man noch sehen, wieviel Gewinn/Verlust man gemacht hat.
                                            </div>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">2022</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                13 Jun 2022
                                            </span>
                                            <h3 class="timeline-header no-border"> Der Wechsel zu <a
                                                    href="https://altv.mp/">alt:V</a> ist vollendet. Das Projekt ist nun
                                                wieder am laufen.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-camera-film text-bg-info"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                18 Feb 2022
                                            </span>
                                            <h3 class="timeline-header"> <a
                                                    href="https://www.youtube.com/watch?v=I5PmerZSd-A"></a> Laptop
                                                Vorstellung vTogether
                                            </h3>
                                            <div class="timeline-body">
                                                <div class="ratio ratio-16x9"> <iframe
                                                        src="https://www.youtube.com/embed/I5PmerZSd-A?si=GQcdb1nyVgsDdEfs"
                                                        allowfullscreen></iframe> </div>
                                            </div>
                                            <div class="timeline-footer">
                                                Es wurde für vTogether ein "Laptop" gebaut, in dem man Anwendungen
                                                verschieben kann, mehrere Anwendungen gleichzeitig auf haben kann, etc.
                                            </div>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                1 Jan 2022
                                            </span>
                                            <h3 class="timeline-header no-border"> Es wurde angefangen das Script von <a
                                                    href="https://islandv.net">IslandV</a> von <a
                                                    href="https://rage.mp/de/">RageMP</a> zu <a
                                                    href="https://altv.mp/">alt:V</a> umgewandelt.
                                                Dabei wurden ca. 20% vom alten Code verwendet. Der Rest wurde neu
                                                geschrieben. Dabei wurde die ganze Serverstruktur verändert, eine neuer
                                                Name wurde gewählt. Dazu musste das ganze Design angepasst werden, das
                                                Control Panel für Admins wurde neu geschrieben. Dazu musste ein neues
                                                Forum her, welches über <a
                                                    href="https://www.woltlab.com/de/">WoltLab</a> lief. Eine eigene 2FA
                                                wurde für Administratoren geschrieben, damit sich im Control Panel
                                                angemeldet werden kann.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-success">2021</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                2 Aug 2021
                                            </span>
                                            <h3 class="timeline-header no-border"> Der Wechsel von <a
                                                    href="https://rage.mp/de/">RageMP</a> zu <a
                                                    href="https://altv.mp/">alt:V</a> hat angefangen.
                                                Im Hintergrund wurden schon die ersten Systemanpassungen gemacht. Neue
                                                Server wurden geholt,
                                                welche eingerichtet werden mussten. Backups wurden gemacht der
                                                Datenbank. Eine neue Datenbank
                                                wurde aufgebaut, wohin später die alte Datenbank von <a
                                                    href="https://rage.mp/de/">RageMP</a> Zeiten hin migriert werden
                                                musste.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-camera-film text-bg-info"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                19 Aug 2021
                                            </span>
                                            <h3 class="timeline-header"> <a
                                                    href="https://www.youtube.com/watch?v=vBbT0xULcJA"></a> Video zur
                                                Entwicklung von IslandV
                                            </h3>
                                            <div class="timeline-body">
                                                <div class="ratio ratio-16x9"> <iframe
                                                        src="https://www.youtube.com/embed/vBbT0xULcJA?si=AAs9gMOXSf4iq06e"
                                                        allowfullscreen></iframe> </div>
                                            </div>
                                            <div class="timeline-footer">
                                                Das Video wurde zum 1 Jährigen Jubiläum veröffentlicht.
                                            </div>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-success">2020</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                27 Nov 2016
                                            </span>
                                            <h3 class="timeline-header no-border"> In zusammenarbeit mit Freunden wurde
                                                angefangen ein kleines Minecraft Projekt auf die Beine zu stellen.
                                                Es sollte ein Reallife Plugin werden, wo man Gewerbe eröffnen , ATM's
                                                nutzen, heiraten, Shops besitzen, Aktien verkaufen, etc. kann. Nach ca.
                                                2
                                                Monaten Entwicklung wurde das ganze dann aus Zeitmangel eingestellt.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-success">2019</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                25 Dez 2019
                                            </span>
                                            <h3 class="timeline-header no-border"> Die Entwicklung von <a
                                                    href="https://islandv.net">IslandV</a> geht los. Es wurde von Grund
                                                auf alles aufgebaut. Der Windows Server
                                                wurde eingerichtet. Danach der Unix Server. Nach ca. 2 Wochen wurde das
                                                Projekt dann in Visual Studio mit C# angefangen. Clientseitig wurde in
                                                Visual Studio Code <a href="https://vuejs.org/">Vue.JS</a> und <a
                                                    href="https://gulpjs.com/">GULP</a> verwendet.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                10 Feb 2019
                                            </span>
                                            <h3 class="timeline-header no-border"> Der Beitritt zum Team von <a
                                                    href="https://gvmp.de/">GVMP</a> als Frontend Entwickler hatte
                                                stattgefunden. Nach ca. 1 Monat wurde dann die
                                                Arbeit als Backend Entwickler auch noch aufgenommen. Alles war auf
                                                Freiwilliger Basis ohne Bezahlung. Das ganze wurde dann am 25 Dez 2019
                                                eingestellt.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-success">2016</span> </div>
                                    <!-- /.timeline-label -->
                                    <!-- timeline item -->
                                    <div> <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                27 Nov 2016
                                            </span>
                                            <h3 class="timeline-header no-border"> In zusammenarbeit mit Freunden wurde
                                                angefangen ein kleines Minecraft Projekt auf die Beine zu stellen.
                                                Es sollte ein Reallife Plugin werden, wo man Gewerbe eröffnen , ATM's
                                                nutzen, heiraten, Shops besitzen, Aktien verkaufen, etc. kann. Nach ca.
                                                2
                                                Monaten Entwicklung wurde das ganze dann aus Zeitmangel eingestellt.
                                            </h3>
                                        </div>
                                    </div> <!-- END timeline item -->
                                    <div> <i class="timeline-icon bi bi-clock-fill text-bg-secondary"></i> </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    </div>
</template>

<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "markusB",
        props: {
            data: String
        },
        data() {
            return {}
        },
        methods: {
            show: function (window) {
                components.Framework.show(window)
            },
        },
        mounted: function () {},
        destroyed: function () {}
    })
</script>

<style scoped>
    @import "../../style/timeline.css";
</style>